import {useState, useRef, useMemo, useEffect} from "react";
import {CircularProgress, TextField,} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import throttle from 'lodash/throttle';
import {flaskAPI} from "../flaskAPI";

export default function SearchAuto(props) {
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);
    const [loading, setLoading] = useState(false);

    const fetch = useMemo(
        () =>
            throttle((request, callback) => {
                flaskAPI.get(props.apiUrl, {params: request}).then(response => callback(response))
            }, 400),
        [],
    );

    useEffect(() => {
        let active = true;

        if (inputValue === '') {
            setOptions(props.value ? [props.value] : []);
            setLoading(false);
            return undefined;
        }
        setLoading(true);

        fetch({ input: inputValue }, (response) => {
            if (response.status === 200 && active) {
                let newOptions = [];

                if (props.value) {
                    newOptions = [props.value];
                }

                if (response.data) {
                    newOptions = [...newOptions, ...response.data];
                }

                setOptions(newOptions);
                setLoading(false);
            }
        });

        return () => {
            active = false;
        };
    }, [props.value, inputValue, fetch]);

    return (
        <Autocomplete
            id="auto-complete"
            style={{width: 250}}
            getOptionLabel={(option) => option[props.optionLabelName]}
            filterOptions={(x) => x}
            options={options}
            autoComplete
            includeInputInList
            filterSelectedOptions
            value={props.value}
            noOptionsText={props.noOptionsText}
            loading={loading}
            onChange={(event, newValue) => {
                setOptions(newValue ? [newValue, ...options] : options);
                // setValue(newValue);
                props.onChange(newValue);
            }}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={props.inputLabel}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
}