import React, {useState} from "react";
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import {NavButton} from '../components/listItems';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import LabelIcon from "@material-ui/icons/Beenhere";
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import UsersTable from '../components/UsersTable';
import {useAuth} from "../context/auth";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ScopeControlDataPage from "./ScopeControlDataPage";
import LightControlDataPage from "./LightControlDataPage";
import GuideControlDataPage from "./GuideControlDataPage";
import LabelPage from "./LabelPage";
import SubLabelPage from "./SubLabelPage";
import LabelRankingPage from "./LabelRankingPage";
import ScoreIcon from '@material-ui/icons/Score';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import LensIcon from '@material-ui/icons/RadioButtonUnchecked';
import {Collapse, ListItem, ListItemIcon, ListItemText} from "@material-ui/core";
import {BlurCircular, ExpandLess, ExpandMore} from "@material-ui/icons";
import ShippingPage from "./ShippingPage";
import ScopeControlReportPage from "./ScopeControlReportPage";
import LeakControlDataPage from "./LeakControlDataPage";
import CloudRefPage from "./CloudRefPage";
import CalibrationPage from "./CalibrationPage";
import TypesPage from "./TypesPage";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey'
        }
    },
    root: {
        display: 'flex',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    demconLogo: {
        display: 'flex',
        alignItems: 'end',
        justifyContent: 'center',
        marginTop: '500px',
        marginBottom: '50px',
        height: '100%'
    },
    appBar: {
        // backgroundColor: "#1C63AF",
        backgroundColor: "#3FA487",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

export default function Dashboard() {
    const classes = useStyles();
    const {roles} = useAuth();
    const {setAuthTokens} = useAuth();
    const open = true;

    const [title, setTitle] = useState("Dashboard");
    const [expandLabel, setExpandLabel] = useState(false);
    const [expandData, setExpandData] = useState(false);
    const [expandReports, setExpandReports] = useState(false);


    const handlePageChange = (title) => {
        setTitle(title);
    };

    return (
        <Router>
            <div className={classes.root}>
                <CssBaseline/>
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            {title}
                        </Typography>
                        <Button onClick={() => setAuthTokens()} color="inherit" startIcon={<ExitToAppIcon/>}>
                            LOG OUT
                        </Button>
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <Divider/>
                    <div className={classes.toolbarIcon}>
                        {}
                    </div>
                    <List>
                        {/* Raw data */}
                        {(roles.includes("admin") || roles.includes("employee")) &&
                        <>
                            <Divider/>
                            <ListItem button onClick={() => setExpandData(!expandData)}>
                                <ListItemIcon>
                                    <DonutSmallIcon />
                                </ListItemIcon>
                                <ListItemText primary="Raw data"/>
                                {expandData ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                            <Collapse in={expandData} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <NavButton nested={true} handleClick={handlePageChange} label="ScopeControl"
                                               url="/data/sc" icon={<DonutSmallIcon />} disabled={false} />
                                    <NavButton nested={true} handleClick={handlePageChange} label="LightControl"
                                               url="/data/lc" icon={<DonutSmallIcon />} disabled={false} />
                                    <NavButton nested={true} handleClick={handlePageChange} label="GuideControl"
                                               url="/data/gc" icon={<DonutSmallIcon />} disabled={false} />
                                    <NavButton nested={true} handleClick={handlePageChange} label="LeakControl"
                                               url="/data/lec" icon={<DonutSmallIcon />} disabled={false} />
                                </List>
                            </Collapse>
                        </>
                        }

                        {/* Reports */}
                        {(roles.includes("admin") || roles.includes("employee") || roles.includes("maquise")) &&
                        <>
                            <Divider/>
                            <ListItem button onClick={() => setExpandReports(!expandReports)}>
                                <ListItemIcon>
                                    <DonutSmallIcon />
                                </ListItemIcon>
                                <ListItemText primary="Reports"/>
                                {expandReports ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                            <Collapse in={expandReports} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <NavButton nested={true} handleClick={handlePageChange} label="DMSC-21002"
                                               url="/reports/sc/489" icon={<DonutSmallIcon />} disabled={false} />
                                    <NavButton nested={true} handleClick={handlePageChange} label="DMSC-21006"
                                               url="/reports/sc/492" icon={<DonutSmallIcon />} disabled={false} />
                                </List>
                            </Collapse>
                        </>
                        }

                        {/* Cloud ref */}
                        {roles.includes("admin") &&
                        <>
                            <Divider/>
                            <NavButton handleClick={handlePageChange} label="Cloud ref" url="/cloudref"
                                       icon={<DonutSmallIcon/>} disabled={false} />
                        </>
                        }

                        {/* Calibration */}
                        {roles.includes("admin") &&
                        <>
                            <Divider/>
                            <NavButton handleClick={handlePageChange} label="Calibration" url="/calibration"
                                       icon={<DonutSmallIcon/>} disabled={false} />
                        </>
                        }

                        {/* Types */}
                        {roles.includes("admin") &&
                        <>
                            <Divider/>
                            <NavButton handleClick={handlePageChange} label="Types" url="/types"
                                       icon={<DonutSmallIcon/>} disabled={false} />
                        </>
                        }

                        {/* Label */}
                        {(roles.includes("admin") || roles.includes("employee") || roles.includes("client")) &&
                        <>
                            <Divider/>
                            <ListItem button onClick={() => setExpandLabel(!expandLabel)}>
                                <ListItemIcon>
                                    <LabelIcon/>
                                </ListItemIcon>
                                <ListItemText primary="Label"/>
                                {expandLabel ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>
                            <Collapse in={expandLabel} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <NavButton nested={true} handleClick={handlePageChange} label="Lens"
                                               url="/label/sc/lens" icon={<LensIcon/>} disabled={false}/>

                                    <NavButton nested={true} handleClick={handlePageChange} label="Lens - Particles"
                                               url="/label/sc/lens/particles" icon={<BlurCircular/>} disabled={false}/>

                                    {(roles.includes("admin") || roles.includes("employee")) &&
                                        <NavButton nested={true} handleClick={handlePageChange} label="Ranking"
                                                   url="/label/ranking" icon={<ScoreIcon/>} disabled={false}/>
                                    }
                                </List>
                            </Collapse>
                        </>
                        }

                        {/* Shipping Logs */}
                        {roles.includes("admin") &&
                        <>
                            <Divider/>
                            <NavButton handleClick={handlePageChange} label="Shipping" url="/shipping"
                                       icon={<LocalShippingIcon/>} disabled={false} />
                        </>
                        }

                        {/* Users */}
                        {roles.includes("admin") &&
                            <>
                            <Divider/>
                            <NavButton handleClick={handlePageChange} label="Users" url="/users"
                                           icon={<SupervisorAccountIcon/>} disabled={false} />
                            </>
                        }


                    </List>
                    <Divider/>
                    <div className={classes.demconLogo}>
                        <img src="/dovideq.webp" width="80%" />
                    </div>
                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer}/>
                    <Container maxWidth="xl" className={classes.container}>
                        <div>
                            <Switch>
                                <Route path="/data/sc" render={() => <ScopeControlDataPage/>} />
                                <Route path="/data/lc" render={() => <LightControlDataPage/>} />
                                <Route path="/data/gc" render={() => <GuideControlDataPage/>} />
                                <Route path="/data/lec" render={() => <LeakControlDataPage/>} />
                                <Route path="/reports/sc/:id_sc" component={ScopeControlReportPage} />
                                <Route path="/label/sc/lens/particles" render={() =>
                                    <SubLabelPage
                                        title="SC Lens particles label"
                                        apiGetUrl="/api/label/sc/lens/particles"
                                        apiCreateUrl="/api/sub-label/sc"
                                    />
                                } />
                                <Route path="/label/sc/lens" render={() =>
                                    <LabelPage
                                        title="SC Lens label"
                                        apiGetUrl="/api/label/sc/lens"
                                        apiCreateUrl="/api/label/sc"
                                    />
                                } />
                                <Route path="/cloudref" render={() => <CloudRefPage/>} />
                                <Route path="/calibration" render={() => <CalibrationPage/>} />
                                <Route path="/types" render={() => <TypesPage/>} />
                                <Route path="/label/ranking" render={() => <LabelRankingPage/>} />
                                <Route path="/shipping" render={() => <ShippingPage/>} />
                                <Route path="/users" render={() => <UsersTable/>} />
                            </Switch>
                        </div>
                    </Container>
                </main>
            </div>
        </Router>
    );
}