import React, {useEffect, useState, useCallback } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Button from "@material-ui/core/Button";
import SearchAuto from "../components/SearchAuto";
import 'date-fns';
import {useLocalStorage} from "../context/useLocalStorage";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TypesTable from "../components/TypesTable";
import {formatFloat} from "../helpers";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DeletionResultsDialog from "../components/DeletionResultsDialog";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
    margin: {
        width: '100%'
    }
}));

export default function TypesPage() {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [selectedBrand, setSelectedBrand] = useLocalStorage("typesPageSelectedBrand", null)

    const [deletionResults, setDeletionResults] = useState(null);
    const [openDeletionResultsDialog, setOpenDeletionResultsDialog] = useState(false);

    const handleOpenDeletionResultsDialog = (results) => {
        setDeletionResults(results);
        setOpenDeletionResultsDialog(true);
    };

    const handleCloseDeletionResultsDialog = () => {
        setOpenDeletionResultsDialog(false);
    };

    const [openDialog, setOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleOpenDialog = (event, rowData) => {
        setSelectedRow(rowData);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedRow(null);
    };

    const handleSubmit = () => {
        setData([]);
        setLoading(true)
        flaskAPI.get('/api/data/msl/unapproved', {
            params: {
                id_brand: selectedBrand ? selectedBrand.id_brand : '',
            }
        })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }

    const handleDownloadExcel = (event, rowData) => {
        if (rowData) {
            flaskAPI.get(
                '/api/data/msl/excel/' + rowData.id_msl, {
                    method: "GET",
                    responseType: "blob",
                }).then(response => {
                setLoading(false);
                if (response.status === 200) {
                    const file = new Blob([response.data], {
                        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                } else {
                }
            }).catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            })
        }
    }

    const handleApproval = (selectedRow) => {
        const id_msl = selectedRow.id_msl
        if (id_msl) {
            flaskAPI.put('/api/data/msl/approved/' + id_msl, {'approved': true}).then(response => {
                if (response.status === 200) {
                    const dataDelete = data.filter((el) => el.id_msl !== id_msl);
                    setData(dataDelete);
                }
            }).catch(error => {
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            })
            handleCloseDialog();
        }
    }

    const handleDelete = (oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData) {
                    flaskAPI.delete('/api/data/msl/' + oldData.id_msl).then(response => {
                        if (response.status === 200) {
                            const dataDelete = data.filter((el) => el.id_msl !== oldData.id_msl);
                            setData(dataDelete);
                            handleOpenDeletionResultsDialog(response.data)
                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                }
            }, 400)
        });
    }

    // Initial submit when loading the page for the first time
    useEffect(() => {
        handleSubmit()
    }, []);

    return (
        <>
            <DeletionResultsDialog deletionResults={deletionResults} open={openDeletionResultsDialog} onClose={handleCloseDeletionResultsDialog} />
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Confirm Approval</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to approve the following type?
                    </DialogContentText>
                    {selectedRow && (
                        <div>
                            <p><strong>ID:</strong> {selectedRow.id_msl}</p>
                            <p><strong>Brand:</strong> {selectedRow.endoscope_brand.brand}</p>
                            <p><strong>Type:</strong> {selectedRow.scopetype}</p>
                            <p><strong>Field of View:</strong> {formatFloat(selectedRow.fieldofview)}</p>
                            <p><strong>Length:</strong> {formatFloat(selectedRow.length)}</p>
                            <p><strong>Thickness:</strong> {formatFloat(selectedRow.thickness)}</p>
                            <p><strong>View Angle:</strong> {formatFloat(selectedRow.viewangle)}</p>
                        </div>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={() => handleApproval(selectedRow)} color="primary">
                        Approve
                    </Button>
                </DialogActions>
            </Dialog>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper className={classes.paper}>
                        {/* Column */}
                        <Grid container spacing={2} direction="column">
                            {/* Brand */}
                            <Grid item container direction="row" spacing={1} alignItems="center">
                                <Grid item>
                                    <SearchAuto
                                        optionLabelName="brand"

                                        inputLabel="Select brand"
                                        noOptionsText="No brands"
                                        value={selectedBrand}
                                        onChange={(newVal) => {
                                            setSelectedBrand(newVal)
                                        }}

                                        apiUrl="/api/data/endoscope/brand/search"
                                    />
                                </Grid>
                                <Grid item style={{ flexGrow: "1" }} />
                                <Grid item>
                                    <Button variant="contained" onClick={() => handleSubmit()} size="large" color="secondary">
                                        Search
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <TypesTable
                        data={data}
                        loading={loading}
                        onApprove={handleOpenDialog}
                        onDownloadExcel={handleDownloadExcel}
                        onDelete={handleDelete}
                    />
                </Grid>
            </Grid>
        </>
    );
}