import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import React from "react";

// Inline styles for the cells
const labelCellStyle = {
    backgroundColor: '#f5f5f5', // Light gray background for the first column
    fontWeight: 'bold', // Bold text for labels
    padding: '8px',
};

const valueCellStyle = {
    padding: '8px',
};

function DeletionResultsDialog(props) {
    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <MuiDialogTitle disableTypography>
                <Typography variant="h6">Succesfully deleted</Typography>
                {props.onClose ? (
                    <IconButton aria-label="close" onClick={props.onClose} style={{ position: 'absolute', right: 16, top: 16, color: '#aaa' }}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
            <MuiDialogContent>
                {props.deletionResults && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>endoscope_history</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.endoscope_history}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>endoscope_history_latest</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.endoscope_history_latest}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>endoscope_serials</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.endoscope_serials}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>endoscope_serials_admin_logs</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.endoscope_serials_admin_logs}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>endoscope_serials_queue</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.endoscope_serials_queue}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>lightcontrol_measurements</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.lightcontrol_measurements}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>masterscope_library</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.masterscope_library}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>measurements</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.measurements}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>service_order_chats</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.service_order_chats}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={labelCellStyle}>service_orders</TableCell>
                                    <TableCell style={valueCellStyle}>{props.deletionResults.service_orders}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </MuiDialogContent>
            <MuiDialogActions>
                <Button onClick={props.onClose} color="primary">
                    Close
                </Button>
            </MuiDialogActions>
        </Dialog>
    );
}

export default DeletionResultsDialog;
