import React, {useEffect, useState} from 'react';
import MaterialTable from '@material-table/core'
import {withStyles} from '@material-ui/core/styles';
import {useAuth} from "../context/auth";
import {tableIcons} from "../styles";
import moment from "moment";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginLeft: 15
    }
});
const columns = [
    {
        title: 'Parameter',
        field: 'name',
        editable: 'never'
    },
    {
        title: 'Count SC',
        field: 'count_sc',
        type: 'numeric',
        editable: 'never'
    },
    {
        title: 'Mean SC',
        field: 'mean_sc',
        type: 'numeric',
        editable: 'never'
    },
    {
        title: 'Count LC',
        field: 'count_lc',
        type: 'numeric',
        editable: 'never'
    },
    {
        title: 'Mean LC',
        field: 'mean_lc',
        type: 'numeric',
        editable: 'never'
    },
    {
        title: 'Cloud ref. value',
        field: 'cloud_ref',
        type: 'numeric',
    },
    {
        title: 'Cloud ref.',
        field: 'cloud_ref',
        type: 'boolean',
        editable: 'never'
    },
    {
        title: 'Last updated',
        field: 'last_update',
        render: rowData => rowData.last_update ? moment(rowData.last_update).format('YYYY-MM-DD HH:mm') : "",
        editable: 'never',
        cellStyle: {
            minWidth: 150,
            maxWidth: 150
        },
    },
];


function CloudRefTable(props) {
    const {setAuthTokens} = useAuth();
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const [open, setOpen] = useState(false);


    return (
        <>
            <MaterialTable
                localization={{
                    header: {
                        actions: ''
                    }
                }}
                icons={tableIcons}
                isLoading={props.loading || loading}
                columns={columns}
                data={props.data}
                options={{
                    search: false,
                    paging: false,
                    sorting: false,
                    toolbar: false,
                }}
                editable={{
                    onRowUpdate: props.onUpdate,
                }}
            />
        </>
    );
}

export default withStyles(styles)(CloudRefTable);
