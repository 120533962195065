import React, {useEffect, useState} from 'react';
import MaterialTable from 'material-table';
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import {tableIcons} from "../styles";
import {useAuth} from "../context/auth";
import {flaskAPI} from "../flaskAPI";
import ImagesDialog from "./ImagesDialog";

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function ScopeControlRawDataTable(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();
    const [open, setOpen] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);
    const [selectedIdMs, setSelectedIdMs] = useState();
    const [images, setImages] = useState([]);

    const handleClickOpen = (rowData) => {
        setSelectedIdMs(rowData.id_ms);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if(selectedIdMs) {
            setLoadingImages(true)
            setImages([]);
            flaskAPI.get("/api/data/sc/images?id_ms=" + selectedIdMs).then(response => {
                if (response.status === 200) {
                    setImages(response.data);
                    setLoadingImages(false)
                } else {
                    // TODO error message
                }
            })
                .catch(error => {
                    setLoadingImages(false)
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [selectedIdMs, setAuthTokens]);

    const columns = [
        { title: 'id_ms', field: 'id_ms', },
        {
            title: 'datetime',
            field: 'datetime',
            render: rowData => moment(rowData.datetime).format('YYYY-MM-DD HH:mm:ss'),
            customSort: (a, b) => moment(a.datetime).unix() - moment(b.datetime).unix(),
            cellStyle: {
                minWidth: 150,
                maxWidth: 150
            }
        },
        { title: 'scopecontrol', field: 'scopecontrol.serialnumber', },
        { title: 'brand', field: 'endoscope_serial.masterscope_library.endoscope_brand.brand', },
        { title: 'type', field: 'endoscope_serial.masterscope_library.scopetype', },
        { title: 'serial', field: 'endoscope_serial.serialnumber', },
        { title: 'cchpautosampled', field: 'cchpautosampled', type: 'numeric'},
        { title: 'cchplevel', field: 'cchplevel', type: 'numeric'},
        { title: 'cchppassed', field: 'cchppassed', type: 'numeric'},
        { title: 'cchpreference', field: 'cchpreference', type: 'numeric'},
        { title: 'cchpresult', field: 'cchpresult', type: 'numeric'},
        { title: 'cchprevisionrefused', field: 'cchprevisionrefused', type: 'numeric'},
        { title: 'cchpthreshold', field: 'cchpthreshold', type: 'numeric'},
        { title: 'ccoriginalvalue', field: 'ccoriginalvalue', type: 'numeric'},
        { title: 'ccresult', field: 'ccresult'},
        { title: 'ccuncorrected', field: 'ccuncorrected', type: 'numeric'},
        { title: 'ccreference', field: 'ccreference', type: 'numeric'},
        { title: 'ccrevisionrefused', field: 'ccrevisionrefused', type: 'numeric'},
        { title: 'fbhpautosampled', field: 'fbhpautosampled', type: 'numeric'},
        { title: 'fbhplevel', field: 'fbhplevel', type: 'numeric'},
        { title: 'fbhppassed', field: 'fbhppassed', type: 'numeric'},
        { title: 'fbhpreference', field: 'fbhpreference', type: 'numeric'},
        { title: 'fbhpresult', field: 'fbhpresult', type: 'numeric'},
        { title: 'fbhpthreshold', field: 'fbhpthreshold', type: 'numeric'},
        { title: 'fbhprevisionrefused', field: 'fbhprevisionrefused', type: 'numeric'},
        { title: 'fboriginalvalue', field: 'fboriginalvalue', type: 'numeric'},
        { title: 'fbresult', field: 'fbresult'},
        { title: 'fbuncorrected', field: 'fbuncorrected', type: 'numeric'},
        { title: 'fbreference', field: 'fbreference', type: 'numeric'},
        { title: 'fbrevisionrefused', field: 'fbrevisionrefused', type: 'numeric'},
        { title: 'fchpautosampled', field: 'fchpautosampled', type: 'numeric'},
        { title: 'fchplevel', field: 'fchplevel', type: 'numeric'},
        { title: 'fchppassed', field: 'fchppassed', type: 'numeric'},
        { title: 'fchpreference', field: 'fchpreference', type: 'numeric'},
        { title: 'fchpresult', field: 'fchpresult', type: 'numeric'},
        { title: 'fchpthreshold', field: 'fchpthreshold', type: 'numeric'},
        { title: 'fchprevisionrefused', field: 'fchprevisionrefused', type: 'numeric'},
        { title: 'fcoriginalvalue', field: 'fcoriginalvalue', type: 'numeric'},
        { title: 'fcresult', field: 'fcresult'},
        { title: 'fcuncorrected', field: 'fcuncorrected', type: 'numeric'},
        { title: 'fcreference', field: 'fcreference', type: 'numeric'},
        { title: 'fcrevisionrefused', field: 'fcrevisionrefused', type: 'numeric'},
        { title: 'fvhpOffset', field: 'fvhpOffset', type: 'numeric'},
        { title: 'fvhpautosampled', field: 'fvhpautosampled', type: 'numeric'},
        { title: 'fvhplevel', field: 'fvhplevel', type: 'numeric'},
        { title: 'fvhppassed', field: 'fvhppassed', type: 'numeric'},
        { title: 'fvhpreference', field: 'fvhpreference', type: 'numeric'},
        { title: 'fvhpresult', field: 'fvhpresult', type: 'numeric'},
        { title: 'fvhpthreshold', field: 'fvhpthreshold', type: 'numeric'},
        { title: 'fvhprevisionrefused', field: 'fvhprevisionrefused', type: 'numeric'},
        { title: 'fvoriginalvalue', field: 'fvoriginalvalue', type: 'numeric'},
        { title: 'fvresult', field: 'fvresult'},
        { title: 'fvuncorrected', field: 'fvuncorrected', type: 'numeric'},
        { title: 'fvreference', field: 'fvreference', type: 'numeric'},
        { title: 'fvrevisionrefused', field: 'fvrevisionrefused', type: 'numeric'},
        { title: 'lthpautosampled', field: 'lthpautosampled', type: 'numeric'},
        { title: 'lthplevel', field: 'lthplevel', type: 'numeric'},
        { title: 'lthppassed', field: 'lthppassed', type: 'numeric'},
        { title: 'lthpreference', field: 'lthpreference', type: 'numeric'},
        { title: 'lthpresult', field: 'lthpresult', type: 'numeric'},
        { title: 'lthpthreshold', field: 'lthpthreshold', type: 'numeric'},
        { title: 'ltoriginalvalue', field: 'ltoriginalvalue', type: 'numeric'},
        { title: 'lthprevisionrefused', field: 'lthprevisionrefused', type: 'numeric'},
        { title: 'ltresult', field: 'ltresult'},
        { title: 'ltuncorrected', field: 'ltuncorrected', type: 'numeric'},
        { title: 'ltreference', field: 'ltreference', type: 'numeric'},
        { title: 'ltrevisionrefused', field: 'ltrevisionrefused', type: 'numeric'},
        { title: 'vahpautosampled', field: 'vahpautosampled', type: 'numeric'},
        { title: 'vahplevel', field: 'vahplevel', type: 'numeric'},
        { title: 'vahppassed', field: 'vahppassed', type: 'numeric'},
        { title: 'vahpreference', field: 'vahpreference', type: 'numeric'},
        { title: 'vahpresult', field: 'vahpresult', type: 'numeric'},
        { title: 'vahpthreshold', field: 'vahpthreshold', type: 'numeric'},
        { title: 'vahprevisionrefused', field: 'vahprevisionrefused', type: 'numeric'},
        { title: 'vaoriginalvalue', field: 'vaoriginalvalue', type: 'numeric'},
        { title: 'varesult', field: 'varesult'},
        { title: 'vauncorrected', field: 'vauncorrected', type: 'numeric'},
        { title: 'vareference', field: 'vareference', type: 'numeric'},
        { title: 'varevisionrefused', field: 'varevisionrefused', type: 'numeric'},
    ];
    return (
        <>
            {/*<ImagesDialog onClose={handleClose} images={images} loading={loadingImages} aria-labelledby="customized-dialog-title" open={open} />*/}
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title="SC - Raw measurements"
                columns={columns}
                data={props.data}
                onRowClick={(event, rowData, togglePanel) => handleClickOpen(rowData)}
                options={{
                    loadingType: "linear",
                    padding: "dense",
                    search: true,
                    sorting: true,
                    exportAllData: true,
                    exportButton: {
                        csv: true,
                        pdf: false
                    },
                    headerStyle: {
                        fontSize: 14,
                    },
                    rowStyle: {
                        fontSize: 11,
                    },
                    pageSize: 15,
                    pageSizeOptions: [15],
                }}
            />
        </>
    );
}

export default withStyles(styles)(ScopeControlRawDataTable);
