import React, {useEffect, useState, useCallback } from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import { useLocalStorage } from '../context/useLocalStorage';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Button from "@material-ui/core/Button";
import SearchAuto from "../components/SearchAuto";
import CloudRefTable from "../components/CloudRefTable";

const useStyles = makeStyles((theme) => ({
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        height: '100vh',
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight2: {
        height: 619.5,
    },
    margin: {
        width: '100%'
    }
}));

export default function CloudRefPage() {
    const classes = useStyles();
    const {setAuthTokens} = useAuth();

    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();

    const [selectedBrand, setSelectedBrand] = useLocalStorage('selectedBrand', null);
    const [selectedMsl, setSelectedMsl] = useLocalStorage('selectedMsl', null);

    const [reRenderType, setReRenderType] = useState(0)

    const handleRecalc = () => {
        setLoading(true)
        if (selectedMsl) {
            flaskAPI.post('/api/data/lt/' + selectedMsl.id_msl, {})
            .then(response => {
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
        }
    }

    const handleSubmit = () => {
        setData([]);
        setLoading(true)
        flaskAPI.get('/api/data/cloudref', {
            params: {
                id_msl: selectedMsl ? selectedMsl.id_msl : '',
            }
        })
            .then(response => {
                setData(response.data);
                setLoading(false);
            })
            .catch(error => {
                setLoading(false);
                if (error && error.response && error.response.status === 401) {
                    setAuthTokens();
                }
            });
    }

    const handleUpdate = (newData, oldData) => {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                if (oldData) {

                    flaskAPI.put('/api/data/cloudref/' + oldData.id_msl, newData).then(response => {
                        if (response.status === 200) {
                            const dataUpdate = [...data];
                            const index = oldData.tableData.id;
                            dataUpdate[index] = newData;
                            setData(dataUpdate);

                            resolve();
                        } else {
                            reject();
                        }
                    }).catch(error => {
                        reject();
                        if (error && error.response && error.response.status === 401) {
                            setAuthTokens();
                        }
                    })
                } else {
                    reject();
                }
            }, 1000)
        });
    }

    // Initial submit when loading the page for the first time
    useEffect(() => {
        handleSubmit()
    }, []);

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    {/* Column */}
                    <Grid container spacing={2} direction="column">
                        <Grid item container direction="row" spacing={1} alignItems="center">
                            <Grid item style={{ flexGrow: "1" }} />
                        </Grid>

                        {/* Brand -> Type -> Serial */}
                        <Grid item container direction="row" spacing={1} alignItems="center">
                            <Grid item>
                                <SearchAuto
                                    optionLabelName="brand"

                                    inputLabel="Select brand"
                                    noOptionsText="No brands"
                                    value={selectedBrand}
                                    onChange={(newVal) => {
                                        setSelectedBrand(newVal)
                                        setSelectedMsl(null)

                                        setReRenderType(reRenderType+1)
                                    }}

                                    apiUrl="/api/data/endoscope/brand/search"
                                />
                            </Grid>
                            <Grid item> <ArrowForwardIcon /> </Grid>
                            <Grid item>
                                <SearchAuto
                                    optionLabelName="scopetype"
                                    key={reRenderType} // Update when brand changes
                                    inputLabel="Select type"
                                    noOptionsText="No types"
                                    value={selectedMsl}
                                    onChange={(newVal) => {
                                        setSelectedMsl(newVal)
                                    }}
                                    apiUrl={!selectedBrand ? "/api/data/msl/search" : ("/api/data/msl/search?id_brand=" + selectedBrand.id_brand)}
                                />
                            </Grid>
                            <Grid item>
                                <Button variant="contained" onClick={() => handleRecalc()} size="large" color="primary" disabled={!selectedMsl}>
                                    recalc
                                </Button>
                            </Grid>
                            <Grid item style={{ flexGrow: "1" }} />
                            <Grid item>
                                <Button variant="contained" onClick={() => handleSubmit()} size="large" color="secondary" disabled={!selectedMsl}>
                                    Search
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <CloudRefTable data={data} loading={loading} onUpdate={handleUpdate}/>
            </Grid>
        </Grid>

    );
}