import React, {useEffect, useState} from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Divider from "@material-ui/core/Divider";
import {Paper, TableCell} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";


const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
        right: theme.spacing(3),
        top: theme.spacing(3),
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

function ImagesDialog(props) {
    const {classes} = props;
    const flattenRowData = () => {
        const flattenedData = {};
        props.columns.forEach((column) => {
            const value = column.field.split('.').reduce((o, i) => o?.[i], props.rowData);
            flattenedData[column.title] = value !== undefined ? value : 'N/A';
        });
        return flattenedData;
    };

    const flattenedRowData = flattenRowData();
    // Identification data
    const identificationData = {
        'ID LMS': flattenedRowData['id_lms'],
        'Datetime': flattenedRowData['datetime'],
        'Light Control': flattenedRowData['lightcontrol'],
        'Brand': flattenedRowData['brand'],
        'Type': flattenedRowData['type'],
        'Serial': flattenedRowData['serial'],
        'Datamatrix': flattenedRowData['datamatrix'],
    };

    // Measurements data including value, threshold, original value, reference, and hpresult
    const measurementsData = {
        'CC': {
            result: flattenedRowData['ccresult'],
            threshold: flattenedRowData['cchpthreshold'],
            original: flattenedRowData['ccoriginalvalue'],
            reference: flattenedRowData['ccreference'],
            hpresult: flattenedRowData['cchpresult']
        },
        'LT': {
            result: flattenedRowData['ltresult'],
            threshold: flattenedRowData['lthpthreshold'],
            original: flattenedRowData['ltoriginalvalue'],
            reference: flattenedRowData['ltreference'],
            hpresult: flattenedRowData['lthpresult']
        },
        'FC': {
            result: flattenedRowData['fcresult'],
            threshold: flattenedRowData['fchpthreshold'],
            original: flattenedRowData['fcoriginalvalue'],
            reference: flattenedRowData['fcreference'],
            hpresult: flattenedRowData['fchpresult']
        },
        'FB': {
            result: flattenedRowData['fbresult'],
            threshold: flattenedRowData['fbhpthreshold'],
            original: flattenedRowData['fboriginalvalue'],
            reference: flattenedRowData['fbreference'],
            hpresult: flattenedRowData['fbhpresult']
        }
    };



    return (
        <Dialog onClose={props.onClose} aria-labelledby="customized-dialog-title" open={props.open} fullWidth maxWidth="lg">
            <DialogTitle id="customized-dialog-title" onClose={props.onClose}>
                <span style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>Images</span>
                <p style={{ margin: '8px 0', fontSize: '1rem', color: '#666' }}>
                    Model trained on August 23, 2024.
                </p>
            </DialogTitle>
            <DialogContent dividers>
                {props.loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '300px' }}>
                        <CircularProgress color="secondary" size={60} />
                    </div>
                ) : (
                    <Grid container spacing={5}>
                        {props.images.map((imageData, index) => {
                            const highestProbability = Math.max(...imageData.prediction.map(pred => pred.probability));
                            return (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                    <div style={{ borderRadius: '8px', overflow: 'hidden', boxShadow: '0 2px 10px rgba(0,0,0,0.1)' }}>
                                        <img src={imageData.url} alt={`Prediction ${index}`} style={{ width: '100%', height: 'auto', display: 'block' }} />
                                        <div style={{ padding: '10px', backgroundColor: '#f9f9f9', borderTop: '1px solid #ddd' }}>
                                            {imageData.prediction.map((pred) => (
                                                <div
                                                    key={pred.label}
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        fontSize: '14px',
                                                        padding: '5px 0',
                                                        fontWeight: pred.probability === highestProbability ? 'bold' : 'normal',
                                                        color: pred.probability === highestProbability ? '#4CAF50' : 'inherit',
                                                    }}
                                                >
                                                    <span>{pred.label}:</span>
                                                    <span>{(pred.probability * 100).toFixed(2)}%</span>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}
                <Divider style={{ margin: '16px 0' }} />
                {/* Identification Data Table */}
                <TableContainer component={Paper} elevation={1} style={{ marginBottom: '20px', border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                    <Table size="small">
                        <TableBody>
                            {Object.entries(identificationData).map(([label, value], index) => (
                                <TableRow key={index}>
                                    <TableCell align="left" style={{ fontWeight: 'bold', padding: '8px', backgroundColor: '#f5f5f5', borderRight: '1px solid #ddd' }}>
                                        {label}
                                    </TableCell>
                                    <TableCell align="left" style={{ padding: '8px' }}>
                                        {value}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Measurements Data Table */}
                <TableContainer component={Paper} elevation={1} style={{ border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)' }}>
                    <Table size="small">
                        <TableBody>
                            <TableRow>
                                {Object.keys(measurementsData).map((key, index) => (
                                    <TableCell key={index} align="center" style={{ fontWeight: 'bold', padding: '8px', backgroundColor: '#3f51b5', color: '#fff', borderRight: '1px solid #ddd' }}>
                                        {key}
                                    </TableCell>
                                ))}
                            </TableRow>
                            <TableRow>
                                {Object.values(measurementsData).map((data, index) => (
                                    <TableCell key={index} align="left" style={{ padding: '8px', backgroundColor: getCellColor(data.hpresult, data.threshold), color: '#fff', borderRight: '1px solid #ddd' }}>
                                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>{data.result}</Typography>
                                        <Typography variant="caption">Result: {data.hpresult}</Typography><br />
                                        <Typography variant="caption">Threshold: {data.threshold}</Typography><br />
                                        <Typography variant="caption">Original: {data.original}</Typography><br />
                                        <Typography variant="caption">Reference: {data.reference}</Typography>
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

            </DialogContent>
        </Dialog>
    );
}
const getCellColor = (hpresult, threshold) => {
    if (hpresult >= threshold) return '#4caf50'; // Green for passing values
    return '#f44336'; // Red for failing values
};
export default withStyles(styles)(ImagesDialog)