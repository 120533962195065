import React, {useEffect, useState} from 'react';
import {flaskAPI} from '../flaskAPI';
import {useAuth} from "../context/auth";
import MaterialTable from '@material-table/core'
import {withStyles} from '@material-ui/core/styles';
import moment from 'moment';
import 'moment-timezone';
import {tableIcons} from "../styles";
import {formatFloat} from "../helpers";
import ImagesDialog from "./ImagesDialog";
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import {useLocalStorage} from "../context/useLocalStorage";
import { ExportCsv, ExportPdf } from '@material-table/exporters';

const styles = () => ({
    root: {
        flexGrow: 1,
    },
    tableTitle: {
        display: 'flex',
    },
    tableTitleText: {
        marginRight: 130,
    }
});

function TypesTable(props) {
    const {classes} = props;
    const {setAuthTokens} = useAuth();
    const [open, setOpen] = useState(false);
    const [loadingImages, setLoadingImages] = useState(false);
    const [selectedIdLms, setSelectedIdLms] = useState();
    const [selectedRowData, setSelectedRowData] = useState({});
    const [images, setImages] = useState([]);

    const [hiddenColumns, setHiddenColumns] = useLocalStorage("typesTableHiddenColumns", []);
    const columns = [
        {title: 'id_msl', field: 'id_msl', type: 'numeric', defaultSort: 'desc'},
        {title: 'brand', field: 'endoscope_brand.brand'},
        {title: 'type', field: 'scopetype'},
        {title: 'approved', field: 'approved', type: 'bool'},
        {
            title: 'fieldofview',
            field: 'fieldofview',
            type: 'numeric',
            render: rowData => formatFloat(rowData.fieldofview)
        },
        {
            title: 'length',
            field: 'length',
            type: 'numeric',
            render: rowData => formatFloat(rowData.length)
        },
        {
            title: 'thickness',
            field: 'thickness',
            type: 'numeric',
            render: rowData => formatFloat(rowData.thickness)
        },
        {
            title: 'viewangle',
            field: 'viewangle',
            type: 'numeric',
            render: rowData => formatFloat(rowData.viewangle)
        },
    ].map((column) => {
        column.hidden = hiddenColumns.includes(column.field);
        return column;
    });

    const handleColumnHiddenChange = (column, hidden) => {
        column.hidden = hidden;
        const updatedHiddenColumns = hidden ? [...hiddenColumns, column.field] : hiddenColumns.filter(col => col !== column.field);
        setHiddenColumns(updatedHiddenColumns);
    };

    const handleClickOpen = (rowData) => {
        setSelectedIdLms(rowData.id_lms);
        setSelectedRowData(rowData); // Store the selected row data
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    useEffect(() => {
        if(selectedIdLms) {
            setLoadingImages(true)
            setImages([]);

            flaskAPI.get("/api/data/msl/search?id_lms=" + selectedIdLms).then(response => {
                if (response.status === 200) {
                    setImages(response.data);
                    setLoadingImages(false)
                } else {
                    // TODO error message
                }
            })
                .catch(error => {
                    setLoadingImages(false)
                    if (error && error.response && error.response.status === 401) {
                        setAuthTokens();
                    }
                });
        }
    }, [selectedIdLms, setAuthTokens]);


    return (
        <>
            {/*<TypesDialog onClose={handleClose} images={images} loading={loadingImages} aria-labelledby="customized-dialog-title" open={open} rowData={selectedRowData} columns={columns} />*/}
            <MaterialTable
                icons={tableIcons}
                isLoading={props.loading}
                title="Types Awaiting Approval"
                columns={columns}
                data={props.data}
                onRowClick={(event, rowData, togglePanel) => handleClickOpen(rowData)}
                onChangeColumnHidden={handleColumnHiddenChange}
                editable={{
                    onRowDelete: props.onDelete,
                }}
                actions={[
                    {
                        icon: () => <SimCardDownloadIcon />,
                        tooltip: 'Download Execl',
                        onClick: props.onDownloadExcel,
                    },
                    {
                        icon: () => <FactCheckIcon />,
                        tooltip: 'Approve',
                        onClick: props.onApprove,
                    }
                ]}
                options={{
                    columnsButton: true,
                    padding: "dense",
                    search: true,
                    sorting: true,
                    exportMenu: [{
                        label: 'Export PDF',
                        exportFunc: (cols, datas) => ExportPdf(cols, datas, 'dovidash_export')
                    }, {
                        label: 'Export CSV',
                        exportFunc: (cols, datas) => ExportCsv(cols, datas, 'dovidash_export')
                    }],
                    headerStyle: {
                        fontSize: 12,
                    },
                    rowStyle: {
                        fontSize: 11,
                    },
                    pageSize: 15,
                    pageSizeOptions: [15],
                }}
            />
        </>
    );
}

export default withStyles(styles)(TypesTable);
